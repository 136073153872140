import Vue from 'vue'
import Store from '../store';
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('../views/Home.vue')
	},
	{
		path: '/wishes',
		name: 'Wishes',
		component: () => import('../views/Wishes.vue')
	},
	{
		path: '/profile',
		name: 'MyWishes',
		component: () => import('../views/MyWishes.vue')
	},
	{
		path: '/campaign',
		name: 'Campaign',
		component: () => import('../views/Campaign.vue')
	},
	{
		path: '/permissions',
		name: 'permissions',
		component: () => import('../views/Permissions.vue')
	},
	{
		path: '/error',
		name: 'error',
		component: () => import('../views/Error.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		return { x: 0, y: 0 }
	}
})
router.afterEach((to, from) => {
	Store.state.isLoading = false;
})

export default router
