<template>
	<v-app>
		<AlertDialog :title="alert.title" :message="alert.message" :active="alert.active" :ok="alert.ok">
			<v-btn v-if="alert.signout" text @click="signout">Abmelden</v-btn>
		</AlertDialog>
		<ImageViewer></ImageViewer>
		<Snowflakes></Snowflakes>
		<ScreenSizeWatcher></ScreenSizeWatcher>
		<Navigation></Navigation>
		<v-main app>
			<v-container app>
				<div v-if="!$store.state.auth.isAuthenticating">
					<router-view id="router-view"></router-view>
				</div>
				<v-row v-else>
					<v-col cols="12" class="text-center">
						<v-progress-circular indeterminate color="primary" :size="50"></v-progress-circular>
					</v-col>
				</v-row>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
import Navigation from './components/App/Navigation';
import ScreenSizeWatcher from './components/App/ScreenSizeWatcher';
import Snowflakes from './components/App/Snowflakes';
import ImageViewer from '@/components/Dialogs/ImageViewer';
import AlertDialog from './components/Global/AlertDialog.vue';
export default {
	data() {
		return {
			alert: {
				title: "",
				message: "",
				active: 0,
				ok: true,
				signout: false
			}
		}
	},
	methods: {
		networkError() {
			this.alert.title = "Netzwerkfehler";
			this.alert.message = "Es ist ein Problem mit der Verbindung zum Server aufgetreten. Bitte überprüfen Sie Ihre Internetverbindung oder versuchen Sie es später erneut.";
			this.alert.active++;
			this.alert.ok = false;
		},
		signout() {
			loginNew.logout();
		}
	},
	components: {
		Navigation,
		ScreenSizeWatcher,
		Snowflakes,
		ImageViewer,
		AlertDialog
	},
};
</script>
<style>
#router-view {
	flex: 1 1 auto;
}
.app-container {
	width: 100%;
	max-width: 1000px!important;
	margin: 0 auto;
}
.v-dialog .theme--dark.v-card, .v-dialog .theme--dark.v-list, .v-dialog .theme--dark.v-stepper {
	background-color: #383838;
}
/* Dark mode top border fix */
.theme--dark .login-new-dialog div, .theme--dark:not(.v-btn--outlined):not(.v-divider) {
	border-top: none;
}
/* Design-Anpassungen */
</style>