import Vue from "vue"

const state = {
    isSignedIn: false,
    authDone: false,
    isAuthenticating: true,
    profile: {},
    userClaims: {
        access: false,
        campaign: false,
        user: false,
        wishes: false
    },
}
const getters = {
    hasPermissions(state) {
        return state.userClaims.manage || state.userClaims.notify
    }
}
const mutations = {
    setUserClaims(state, claims) {
        
        if(claims && claims.permissions) {
            state.userClaims.access = claims.permissions.access === true
            state.userClaims.campaign = claims.permissions.campaign === true
            state.userClaims.users = claims.permissions.users === true
            state.userClaims.wishes = claims.permissions.wishes === true
        }else{
            state.userClaims.access = false
            state.userClaims.campaign = false
            state.userClaims.users = false
            state.userClaims.wishes = false
        }
        console.log(claims)
        
    },
    setUserProfile(state, profile) {
        Vue.set(state, "profile", profile)
    }
}
export default {state, getters, mutations}