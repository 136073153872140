<template>
<v-app-bar app fixed color="navigation" elevate-on-scroll>
    <DenseContainer class="py-0">
        <div class="d-flex flex-row align-center">
            <a @click="clickStore"><img class="v-app-bar-new-icon" :src="$vuetify.theme.dark ? images.logoDark : images.logo" width="90px"></a>
            <v-spacer></v-spacer>
            <v-toolbar-title v-if="!$store.state.layout.dense">Wunschbaumaktion</v-toolbar-title>
            <v-spacer></v-spacer>
            <div id="loginnew-dropdown"></div>
        </div>
    </DenseContainer>
    <template v-slot:extension>
        <v-tabs v-model="tab" centered v-if="!$store.state.auth.isAuthenticating && ($store.state.config.campaign.started === true || $store.state.auth.userClaims.access)" color="primaryLight ">
            <v-tab @click="$router.push('/wishes')">Offene Wünsche</v-tab>
            <v-tab @click="$router.push('/profile')">
                <v-badge color="primary" :content="uncompletedWishes.length + ''">Meine Erfüllungen</v-badge>
            </v-tab>
        </v-tabs>
      </template>
</v-app-bar>
</template>
<script>
export default {
    data() {
        return {
            tab: 0,
            images: {
                logo: require("@/assets/Logos/logo_colored.svg"),
                logoDark: require("@/assets/Logos/logo_dark.svg")
            }
        }
    },
    watch: {
        "$route.name"() {
            switch (this.$route.name) {
                case "Wishes":
                    this.tab = 0;
                    break;
                case "MyWishes":
                    this.tab = 1;
                    break;
                default:
                    this.tab = null;
                    break;
            }
        }
    },
    computed: {
        uncompletedWishes() {
            var wishes = [];
            for(var key in this.$store.state.takenWishes) {
                if(this.$store.state.takenWishes[key].completed != true) {
                    wishes[wishes.length] = this.$store.state.takenWishes[key];
                }
            }
            return wishes;
        },
        showApps() {
            return this.$route.name == "Apps";
        }
    },
    methods: {
        scrollListener() {
            this.bigNavigation = window.scrollY <= 0;
        },
        clickStore() {
            this.$router.push("/");
        },
        signOut() {
            this.$router.push("/logout");
        }
    }
}
</script>
<style>
#loginnew-dropdown .login-new-avatar span {
    display: none;
}
/* Fix, damit die Tabs unter dem Login NEW Dialog sind*/
.v-toolbar__content, .v-toolbar__extension {
    position: initial!important ;
}
/* Profiltext unter Profilbild ausblenden, da dort der Platz fehlt */
.v-toolbar__content .login-new-avatar span.login-new-avatar-below {
    display: none;
}
/* Fix, damit die Tabs bei sehr kleinen Handys funktionieren */
.v-slide-group__prev{
    display: none!important;
}
</style>
<style scoped>
#loginnew-dropdown {
    z-index: 9999999;
}
/* Login NEW closer fix */
header.v-app-bar {
    contain: initial;
    transform: none !important;
}
</style>