import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import Vue from 'vue'
import Vuex from 'vuex'
import { firestore } from '../firebase'
import auth from './modules/auth'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		isLoading: false,
		fullscreenImage: "null",
		layout: {
			dense: false
		},
		config: {
			campaign: {},
			text: {}
		},
		adminMode: false,
		wishes: {},
		wishesLive: {},
		takenWishes: {},
		firestoreListeners: {}
	},
	mutations: {
		dense (state, value) {
			state.layout.dense = value;
		}
	},
	actions: {
		attachTakenListener({dispatch, state}) {
			return new Promise((resolve, reject) => {
				if(state.firestoreListeners.hasOwnProperty("takenWishes") && state.firestoreListeners.takenWishes != null) {
					resolve();
					return;
				}
				state.firestoreListeners.takenWishes = onSnapshot(query(collection(firestore, "wishes"), where("uid", "==", loginNew.getProfile().sub)), docs => {
					state.takenWishes = {};
					docs.forEach(doc => {
						var newDoc = doc.data();
						newDoc._id = doc.id;
						Vue.set(state.takenWishes, doc.id, newDoc);
					});
				});
			});
		},
		attachWishesListener({dispatch, state}) {
			return new Promise((resolve, reject) => {
				if(state.firestoreListeners.hasOwnProperty("wishes") && state.firestoreListeners.wishes != null) {
					resolve();
					return;
				}
				const queryRef = query(collection(firestore, "wishes"), where("visible", "==", true), where("uid", "==", null), orderBy("timestamp"))
				
				state.firestoreListeners.wishes = onSnapshot(queryRef, docs => {
					state.wishesLive = {};
					docs.forEach(doc => {
						var newDoc = doc.data();
						newDoc._id = doc.id;
						Vue.set(state.wishes, doc.id, newDoc);
						Vue.set(state.wishesLive, doc.id, true);
					});
				});
			});
		},
	},
	modules: {
		auth
	}
})
