import { waitForFirebaseAuth, auth, functions, firestore } from './firebase'
import { getIdTokenResult, signInWithCustomToken, signOut } from 'firebase/auth'
import { httpsCallable } from 'firebase/functions'
import store from './store'
import router from './router'
import { collection, doc, getDoc } from 'firebase/firestore'

const enableFirebaseTokenCache = false

loginNew.init(
	process.env.VUE_APP_LOGIN_NEW_CLIENT_ID,
	location.protocol + "//" + location.host + "/",
    [],
	"openid usercentics profile email aid signin:microsoft"
)
loginNew.onAuthStateChanged(async () => {
	store.state.auth.isAuthenticating = true
	if(loginNew.isSignedIn()) {
        await waitForFirebaseAuth()
        const profile = loginNew.getProfile()
        if(enableFirebaseTokenCache && auth.currentUser && auth.currentUser.uid  == profile.sub) {
            const tokenResult = await getIdTokenResult(auth.currentUser, true)
            store.commit("setUserClaims", tokenResult.claims)
            store.commit("setUserProfile", profile)
            const configDoc = await getDoc(doc(collection(firestore, "configuration"), "campaign"))
            if(configDoc.exists) {
                store.state.config.campaign = configDoc.data()
                store.state.auth.isSignedIn = true
                store.state.auth.isAuthenticating = false
                store.state.auth.authDone = true
            }else{
                store.state.auth.isSignedIn = false
                store.state.auth.isAuthenticating = false
                store.state.auth.authDone = true
                router.push("/error").catch(() => {})
            }
        }else{
            const getIdToken = httpsCallable(functions, "getIdToken");
            const response = await getIdToken({ id: loginNew.getIdToken() });
            if (response.data.response === "ok") {
                await signInWithCustomToken(auth, response.data.id_token);
                const tokenResult = await getIdTokenResult(auth.currentUser, true)
                store.commit("setUserClaims", tokenResult.claims)
                store.commit("setUserProfile", profile)
                const configDoc = await getDoc(doc(collection(firestore, "configuration"), "campaign"))
                if(configDoc.exists) {
                    store.state.config.campaign = configDoc.data()
                    store.state.auth.isSignedIn = true
                    store.state.auth.isAuthenticating = false
                    store.state.auth.authDone = true
                }else{
                    store.state.auth.isSignedIn = false
                    store.state.auth.isAuthenticating = false
                    store.state.auth.authDone = true
                    router.push("/error").catch(() => {})
                }
            }else{
                if(auth.currentUser != null) {
                    await auth.signOut()
                }
                if(response.data.error == "invalid_permissions") {
                    store.commit("setUserClaims", null)
                    store.commit("setUserProfile", profile)
                    store.state.auth.isSignedIn = false
                    store.state.auth.isAuthenticating = false
                    store.state.auth.authDone = true
                    router.push("/permissions").catch(() => {})
                }else{
                    console.error(response.data);
                    store.commit("setUserClaims", null)
                    store.commit("setUserProfile", profile)
                    store.state.auth.isSignedIn = false
                    store.state.auth.isAuthenticating = false
                    store.state.auth.authDone = true
                    router.push("/error").catch(() => {})
                }
            }
        }
	}else{
        store.commit("setUserClaims", null)
        store.commit("setUserProfile", {})
        await waitForFirebaseAuth()
        if(auth.currentUser != null) {
            await auth.signOut()
        }
        loginNew.onCookieStateChanged(() => {
            if(router.currentRoute.query.hasOwnProperty("share")) {
                sessionStorage.setItem("share", router.currentRoute.query.share);
            }
            loginNew.auth()
        })
        store.state.auth.isSignedIn = false
        store.state.auth.isAuthenticating = true
        store.state.auth.authDone = true
	}
})
loginNew.dropdown.addButton(loginNew.SIGNED_IN, "mycards", "Meine Erfüllungen", require('@/assets/Icons/gift.svg'), () => {
    router.push('/profile')
});