import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import './firebase'
import './loginNew'

import VueCroppa from 'vue-croppa'
Vue.use(VueCroppa)

Vue.config.productionTip = false

// Global components
import DenseContainer from './components/Global/DenseContainer'
import AlertDialog from './components/Global/AlertDialog'
import ConfirmDialog from './components/Global/ConfirmDialog'
Vue.component("DenseContainer", DenseContainer)
Vue.component("AlertDialog", AlertDialog)
Vue.component("ConfirmDialog", ConfirmDialog)

document.cookie = "loginnew=true max-age=2592000 path=/"

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')