<template>
<v-dialog v-model="dialog" width="unset" max-width="100%" max-height="100%">
    <v-card>
        <v-img :src="$store.state.fullscreenImage">
            <v-btn icon dark @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-img>
    </v-card>
</v-dialog>
</template>
<script>
export default {
    data() {
        return {
            dialog: false,
        }
    },
    watch: {
        "$store.state.fullscreenImage"() {
            if(this.$store.state.fullscreenImage != null) {
                this.dialog = true;
            }
        },
        dialog() {
            if(this.dialog == false) {
                this.$store.state.fullscreenImage = null;
            }
        }
    }
}
</script>