import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

var dark = false;
if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
	dark = true;
}

export default new Vuetify({
	icons: {
		iconfont: 'mdi',
	},
	theme: {
		themes: {
			light: {
				primary: '#9c0047',
				secondary: '#2b6ea9',
				accent: '#275c9b',
				error: '#b71c1c',
				primaryLight: '#9c0047',
				navigation: '#fff'
			},
			dark: {
				primary: '#9c0047',
				secondary: '#2b6ea9',
				accent: '#275c9b',
				error: '#f34848',
				primaryLight: '#fff',
				navigation: '#121212'
			},
		},
		dark: dark
	}
});