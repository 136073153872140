import { initializeApp } from "firebase/app"
import { getFirestore, connectFirestoreEmulator, ba } from "firebase/firestore"
import { getFunctions, connectFunctionsEmulator } from "firebase/functions"
import { getAuth, onAuthStateChanged, connectAuthEmulator } from "firebase/auth"
import { getStorage, connectStorageEmulator } from "firebase/storage"

const firebaseApp = initializeApp({
	apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
	authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
	storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
	messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.VUE_APP_APPID
})

const firestore = getFirestore(firebaseApp)
const functions = getFunctions(firebaseApp, "europe-west1")
const auth = getAuth(firebaseApp)
const storage = getStorage(firebaseApp)

if(location.hostname == "localhost") {
    connectFirestoreEmulator(firestore, "localhost", 8081)
    connectFunctionsEmulator(functions, "localhost", 5001)
    connectAuthEmulator(auth, "http://localhost:9099")
    connectStorageEmulator(storage, "localhost", 9199)
}

const waitForFirebaseAuth = () => {
    return new Promise((resolve) => {
        let listener = onAuthStateChanged(auth, () => {
            resolve()
            listener()
            listener = null
        })
    })
}

export {
    firestore,
    functions,
    auth,
    storage,
    waitForFirebaseAuth
}